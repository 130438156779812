import * as React from "react";

import Intro from "../Intro";
import Greeting from "../Greeting";
import AreaOfLife from "../AreaOfLife";
import MainFeeling from "../MainFeeling";
import CopingMechanism from "../CopingMechanism";
import CopingBehaviour from "../CopingBehaviour";
import Close from "../Close";
import Results from "../Results";
import ResultsFinal from "../ResultsFinal";
import useWizard, { WizardContext } from "../../hooks/useWizard";
import ProgressBar from "../ProgressBar";
import logo from "../../assets/logo.svg";

const STEPS = [
  <Intro />,
  <Greeting />,
  <AreaOfLife />,
  <MainFeeling />,
  <CopingMechanism />,
  <CopingBehaviour />,
  <Close />,
  <Results />,
  <ResultsFinal />,
];

const WizardProvider: React.FC = () => {
  const context = useWizard(STEPS.length);
  return (
    <>
      <div>
        <div className="px-4">
          <div className="max-w-lg mx-auto">
            <WizardContext.Provider value={context}>
              <ProgressBar />
              <div className="bg-white shadow sm:rounded-lg px-4 pb-4 pt-2">
                {STEPS[context.currentStep]}
              </div>
              <div className="flex flex-row justify-center items-center mt-6">
                <p className="mr-3 text-blue text-sm">Powered by</p>
                <img src={logo} className="h-7" alt="Powered by Clearhead" />
              </div>
            </WizardContext.Provider>
          </div>
        </div>
      </div>
    </>
  );
};

export default WizardProvider;
