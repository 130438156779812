import * as React from "react";
import { useWizardContext } from "../../hooks/useWizard";
import ClearheadCharacter from "../ClearheadCharacter";
import withAnd from "../../lib/withAnd";

const responses: Record<string, string> = {
  Avoidance:
    "One reason we avoid things is because it prevents us from feeling {emotion}. This might help at first 🤔, except then avoidance itself can become the problem 😔.",
  "Excessive distraction":
    "One reason we distract ourselves is because it prevents us from feeling {emotion}. This might help at first 🤔, except then what you're doing to distract yourself can become a problem 😔.",
  "Emotional outbursts":
    "It can feel good to vent when things build up, and it can actually be a healthy form of coping. In saying that, emotional outbursts can impact on our relationships with other people.",
  "Drug Use":
    "One reason we use drugs is because it prevents us from feeling {emotion}. This might work at first 🤔 , except then drug use can become a problem 😔.",
  "Alcohol Use":
    "Drinking can help to prevent us from feeling {emotion}. This might work at first 🤔, except then drinking alcohol can become a problem 😔.",
  Other:
    "The way we cope usually prevents us from feeling {emotion}. Sometimes this helps at first 🤔, but then what you’re doing to cope can become the problem 😔.",
};

const concerns: string[] = [
  "I’m worthless",
  "I’m hopeless",
  "I’m unlikeable",
  "I’m helpless",
  "Worrying",
  "I’m a failure",
  "Self-hate",
  "I’m useless",
  "I must be perfect",
  "It’s out of control",
  "I have trust issues",
];

function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(" ");
}

const CopingBehaviour = () => {
  const context = useWizardContext();

  const isThoughts = React.useMemo(() => {
    if (context.data) {
      return Object.keys(context.data.thoughts).length > 0;
    }
    return false;
  }, [context.data]);

  let copingMech = responses.Other;
  copingMech = copingMech.replace(
    "{emotion}",
    context.data?.mainFeeling?.toLowerCase() || ""
  );

  return (
    <div>
      <div className="mt-8 mb-4 text-center mx-auto">
        <ClearheadCharacter />
      </div>
      <p className="mb-4 text-center">{copingMech}</p>
      <p className="mb-4 text-center">
        {context.data?.challengeAffectedBy &&
        context.data?.challengeAffectedBy.length > 0
          ? Object.keys(context.data.copingMechanism).length
            ? `Sometimes when we’re coping through ${withAnd(
                Object.keys(context.data.copingMechanism)
              ).toLowerCase()} we experience negative thoughts. Select the thoughts you have noticed yourself thinking:`
            : `Sometimes when we’re coping with our situations we experience negative thoughts. Select the thoughts you have noticed yourself thinking:`
          : `Sometimes when we’re coping with our situations we experience negative thoughts. Select the thoughts you have noticed yourself thinking:`}
      </p>
      <div className="mb-4">
        <div className="mt-1 mb-6">
          <div className="space-y-4">
            {concerns.map((concern) => (
              <button
                key={concern}
                value={concern}
                className={classNames(
                  context.data.thoughts[concern]
                    ? "border-blue"
                    : "border-gray-300",
                  "w-full block bg-white border rounded-md px-3 py-2 cursor-pointer sm:flex sm:justify-between focus:outline-none"
                )}
                onClick={() => {
                  let data = { ...context.data.thoughts };
                  if (context.data.thoughts[concern]) {
                    delete data[concern];
                  } else {
                    data[concern] = true;
                  }
                  context.updateData({
                    thoughts: data,
                  });
                }}
              >
                <div className="flex items-center">
                  <div className="text-sm">
                    <p className="font-medium text-gray-900">{concern}</p>
                  </div>
                </div>
              </button>
            ))}
          </div>
        </div>
      </div>
      <div className="flex justify-between">
        <button
          type="button"
          onClick={context.onPrevious}
          className="inline-flex items-center px-4 py-2 border border-blue text-sm font-medium rounded-full shadow-sm text-blue bg-transparent focus:outline-none"
        >
          Previous
        </button>
        <button
          type="button"
          disabled={!isThoughts}
          onClick={context.onNext}
          data-id="Thoughts Complete"
          className={`${
            isThoughts
              ? "cursor-pointer bg-blue hover:bg-blue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue"
              : "cursor-not-allowed bg-gray-300 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-300"
          } inline-flex items-center px-5 py-2 border border-transparent text-base font-medium rounded-full shadow-sm text-white`}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default CopingBehaviour;
