import * as React from "react";
import { useWizardContext } from "../../hooks/useWizard";
import ClearheadCharacter from "../ClearheadCharacter";

import { heapTrack } from "../../lib/heap";

const matcher: Record<string, string> = {
  "I’m worthless": "I’m not good enough",
  "I’m hopeless": "I’m not in control",
  "I’m unlikeable": "I’m not good enough",
  "I’m helpless": "I’m not in control",
  Worrying: "I’m not in control",
  "I’m a failure": "I’m not good enough",
  "Self-hate": "I’m not good enough",
  "I’m useless": "I’m not good enough",
  "I must be perfect": "I’m not good enough",
  "It’s out of control": "I’m not in control",
  "I have trust issues": "I’m not in control",
};

const responses: Record<string, string> = {
  "I’m not good enough":
    "The journey to feeling worthy and self-confident takes time. Thanks for being open about your thoughts. Letting these things out is a key step toward wellbeing.",
  "I’m not in control":
    "The journey to feeling in control of your life takes time. Thanks for being open about your thoughts. Letting these things out is a key step toward wellbeing.",
  Both: "The journey to feeling worthy, self-confident and in control of your life takes time. Thanks for being open about your thoughts. Letting these things out is a key step toward wellbeing.",
};

const Close = () => {
  const context = useWizardContext();
  let response = "Let's go view your results";
  const types: Record<string, any> = {};
  Object.keys(context.data.thoughts).forEach((key) => {
    types[matcher[key]] = true;
  });
  if (Object.keys(types).length > 1) {
    response = responses["Both"];
  } else {
    Object.keys(types).forEach((x) => {
      response = responses[x];
    });
  }

  const onNext = React.useCallback(() => {
    if (context.data) {
      if (context.data.mainFeeling) {
        heapTrack("Main Feeling", {
          mainFeeling: context.data.mainFeeling,
        });
      }
      if (context.data.challengeAffectedBy) {
        heapTrack("Affected By", {
          affectedBy: context.data.challengeAffectedBy,
        });
      }
      if (context.data.challengeAffectedByOther) {
        heapTrack("Affected By Other", {
          affectedByOther: context.data.challengeAffectedByOther,
        });
      }
      if (context.data.copingMechanism) {
        Object.keys(context.data.copingMechanism).forEach((key) => {
          heapTrack("Coping Mechanism", {
            copingMechanism: key,
          });
        });
      }
      if (context.data.copingMechanismOther) {
        heapTrack("Coping Mechanism Other", {
          copingMechanismOther: context.data.copingMechanismOther,
        });
      }
      if (context.data.thoughts) {
        Object.keys(context.data.thoughts).forEach((key) => {
          heapTrack("Thoughts", {
            thoughts: key,
          });
        });
      }
      heapTrack("Assessment Completed");
      context.onNext();
    }
  }, [context]);

  return (
    <div>
      <div className="mt-8 mb-8 text-center">
        <ClearheadCharacter />
        <p className="mb-3">{response}</p>
      </div>
      <div className="flex justify-between">
        <button
          type="button"
          onClick={context.onPrevious}
          className="inline-flex items-center px-4 py-2 border border-blue text-sm font-medium rounded-full shadow-sm text-blue bg-transparent focus:outline-none"
        >
          Previous
        </button>
        <button
          type="button"
          data-id="Close Complete"
          onClick={onNext}
          className={`inline-flex items-center px-5 py-2 border border-transparent text-base font-medium rounded-full shadow-sm text-white cursor-pointer bg-blue hover:bg-blue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue`}
        >
          Got it
        </button>
      </div>
    </div>
  );
};

export default Close;
