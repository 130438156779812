import * as React from "react";

export type WizardData = {
  name: string;
  challengeAffectedBy: string;
  challengeAffectedByOther: string;
  mainFeeling: string;
  copingMechanism: Record<string, boolean>;
  copingMechanismOther: string;
  thoughts: Record<string, boolean>;
};

export interface IUseWizard {
  data: WizardData;
  updateData: (partialData: Partial<WizardData>) => void;
  currentStep: number;
  totalSteps: number;
  setCurrentStep: (step: number) => void;
  onNext: () => Promise<void>;
  onPrevious: () => Promise<void>;
  isFirstStep: () => boolean;
  isLastStep: () => boolean;
  clearData: () => void;
}

export const WizardContext = React.createContext<IUseWizard>({
  data: {
    name: "",
    challengeAffectedBy: "",
    challengeAffectedByOther: "",
    mainFeeling: "",
    copingMechanism: {},
    copingMechanismOther: "",
    thoughts: {},
  },
  updateData: (partialData: Partial<WizardData>) => {
    console.log("implement updateData: ", partialData);
  },
  currentStep: 0,
  totalSteps: 0,
  onNext: async () => {
    console.log("implement the onNext");
  },
  onPrevious: async () => {
    console.log("implement the onPrevious");
  },
  setCurrentStep: (step: number) => {
    console.log("implementation on setCurrentStep", step);
  },
  isFirstStep: () => false,
  isLastStep: () => false,
  clearData: () => {
    console.log("implementation on clearData");
  },
});

export const useWizardContext = (): IUseWizard => {
  const context = React.useContext(WizardContext);
  return context;
};

const defaultData = {
  name: "",
  challengeAffectedBy: "",
  challengeAffectedByOther: "",
  mainFeeling: "",
  copingMechanism: {},
  copingMechanismOther: "",
  thoughts: {},
};

const useWizard = (steps: number): IUseWizard => {
  const [data, setData] = React.useState<WizardData>(defaultData);
  const [totalSteps] = React.useState<number>(steps);
  const [currentStep, setCurrentStep] = React.useState<number>(0);

  const updateData = React.useCallback(
    (partialData: Partial<WizardData>) => {
      setData((prevState) => ({
        ...prevState,
        ...partialData,
      }));
    },
    [setData]
  );

  const clearData = () => setData(defaultData);

  const isLastStep = React.useCallback(() => {
    return currentStep === steps - 1;
  }, [currentStep, steps]);

  const isFirstStep = React.useCallback(() => {
    return currentStep === 0;
  }, [currentStep]);

  const onNext = React.useCallback(async () => {
    if (currentStep < steps) {
      setCurrentStep(currentStep + 1);
    }
  }, [currentStep, steps]);

  const onPrevious = React.useCallback(async () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  }, [currentStep]);

  return {
    data,
    updateData,
    currentStep,
    totalSteps,
    setCurrentStep,
    onNext,
    onPrevious,
    isFirstStep,
    isLastStep,
    clearData,
  };
};

export default useWizard;
