import * as React from "react";
import { useWizardContext } from "../../hooks/useWizard";
import ClearheadCharacter from "../ClearheadCharacter";

const Intro = () => {
  const context = useWizardContext();

  const isNameValid = React.useMemo(() => {
    if (context.data) {
      return context.data.name && context.data.name.length > 0;
    }
    return false;
  }, [context.data]);

  return (
    <div>
      <div className="mt-8 mb-4 text-center mx-auto">
        <ClearheadCharacter />
        <p>Hello, I’m your wellbeing guide.</p>
        <p className="mb-4">My purpose is to guide you to wellbeing.</p>
        <p className="mb-4">What's your name?</p>
      </div>
      <div className="mb-4">
        <div className="mt-1">
          <input
            type="text"
            name="name"
            id="name"
            onChange={(e) =>
              context.updateData({ name: e.currentTarget.value })
            }
            value={context.data?.name ?? ""}
            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
          />
        </div>
      </div>
      <div className="flex justify-end">
        <button
          type="button"
          disabled={!isNameValid}
          onClick={context.onNext}
          data-id="Intro Step Complete"
          className={`${
            isNameValid
              ? "cursor-pointer bg-blue hover:bg-blue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue"
              : "cursor-not-allowed bg-gray-300 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-300"
          } inline-flex items-center px-5 py-2 border border-transparent text-base font-medium rounded-full shadow-sm text-white`}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default Intro;
