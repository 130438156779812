import * as React from "react";
import { useWizardContext } from "../../hooks/useWizard";
import ClearheadCharacter from "../ClearheadCharacter";
import me from "../../assets/me.png";
import them from "../../assets/them.png";
import thinking from "../../assets/thinking.png";
import coping from "../../assets/coping.png";
import withAnd from "../../lib/withAnd";

import { heapTrack } from "../../lib/heap";

const Results = () => {
  const context = useWizardContext();

  const onNext = React.useCallback(() => {
    if (context.data) {
      heapTrack("Viewed Results");
      context.onNext();
    }
  }, [context]);

  return (
    <div>
      <div className="mt-8 mb-4 text-center mx-auto">
        <ClearheadCharacter />
        <p className="mb-3">Well done, you’ve completed your Check Up! 🙌</p>
        <p className="mb-3">
          Together, we have revealed the following insights:
        </p>
      </div>
      <div className="mb-4 text-center">
        <div className="bg-white shadow sm:rounded-lg mb-4">
          <div className="px-4 py-5 sm:p-6">
            <img
              src={me}
              className="rounded-full h-24 w-24 mx-auto mb-3"
              alt="me"
            />
            <p>
              Feeling <b>{context.data.mainFeeling.toLowerCase()}</b> is related
              to your{" "}
              <b>
                {context.data.challengeAffectedBy
                  ? context.data.challengeAffectedBy.toLowerCase()
                  : context.data.challengeAffectedByOther.toLowerCase()}
              </b>
              .
            </p>
          </div>
        </div>
        <div className="bg-white shadow sm:rounded-lg mb-4">
          <div className="px-4 py-5 sm:p-6">
            <img
              src={them}
              className="rounded-full h-24 w-24 mx-auto mb-3"
              alt="them"
            />
            <p>
              You're using{" "}
              <b>
                {Object.keys(context.data.copingMechanism).length
                  ? withAnd(
                      Object.keys(context.data.copingMechanism)
                    ).toLowerCase()
                  : `"${context.data.copingMechanismOther}"`}
              </b>{" "}
              to cope with feeling{" "}
              <b>{context.data.mainFeeling.toLowerCase()}</b>.
            </p>
          </div>
        </div>
        <div className="bg-white shadow sm:rounded-lg mb-4">
          <div className="px-4 py-5 sm:p-6">
            <img
              src={coping}
              className="rounded-full h-24 w-24 mx-auto mb-3"
              alt="coping"
            />
            <p className="mb-4">
              The way you’re coping is contributing to negative thoughts of{" "}
              <b>"{Object.keys(context.data.thoughts).join(" / ")}"</b>. And
              your thought patterns are linking back to feeling{" "}
              <b>{context.data.mainFeeling.toLowerCase()}</b> and this pushes
              you to{" "}
              <b>
                {Object.keys(context.data.copingMechanism).length
                  ? withAnd(
                      Object.keys(context.data.copingMechanism)
                    ).toLowerCase()
                  : `"${context.data.copingMechanismOther}"`}
              </b>
              .
            </p>
            <p>
              <b className="text-green">But you can break the cycle!</b>
            </p>
          </div>
        </div>
        <div className="bg-green-lightest shadow sm:rounded-lg mb-4">
          <div className="px-4 py-5 sm:p-6">
            <img
              src={thinking}
              className="rounded-full h-24 w-24 mx-auto mb-3"
              alt="thinking"
            />
            <p className="mb-4">
              You can learn to manage your emotions better, cope more
              positively, and reframe your thoughts, one step at a time.
            </p>
          </div>
        </div>
      </div>
      <div className="flex justify-between">
        <button
          type="button"
          onClick={context.onPrevious}
          className="inline-flex items-center px-4 py-2 border border-blue text-sm font-medium rounded-full shadow-sm text-blue bg-transparent focus:outline-none"
        >
          Previous
        </button>
        <button
          type="button"
          onClick={onNext}
          data-id="Intro Step Complete"
          className="cursor-pointer bg-blue hover:bg-blue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue inline-flex items-center px-5 py-2 border border-transparent text-base font-medium rounded-full shadow-sm text-white"
        >
          What can I do?
        </button>
      </div>
    </div>
  );
};

export default Results;
