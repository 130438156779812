import { useWizardContext } from "../../hooks/useWizard";

const ProgressBar = () => {
  const { currentStep, totalSteps } = useWizardContext();
  return (
    <div>
      <div className="mt-6 mb-6" aria-hidden="true">
        <div className="bg-gray-200 rounded-full overflow-hidden">
          <div
            className="h-2 bg-blue rounded-full"
            style={{ width: `${(currentStep / (totalSteps - 1)) * 100}%` }}
          />
        </div>
      </div>
    </div>
  );
};

export default ProgressBar;
