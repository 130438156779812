/* eslint-disable react/jsx-no-target-blank */
import ClearheadCharacter from "../ClearheadCharacter";

const Results = () => {
  return (
    <div>
      <div className="mt-8 mb-4 text-center mx-auto">
        <ClearheadCharacter />
        <p className="mb-3">
          Well done, for completing your Check-Up and reading your results! 🤩
        </p>
      </div>
      <div className="mb-4">
        <div className="bg-white shadow sm:rounded-lg">
          <div className="px-4 py-5 sm:p-6">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Find related resources
            </h3>
            <div className="mt-2 max-w-xl text-sm text-gray-500">
              <p>
                First steps houses a good number of resources to support you,
                click on any category below to learn more:
              </p>
              <ul className="list-disc pl-6">
                <li>
                  <a
                    href="https://firststeps.nz/resources"
                    className="text-blue"
                    data-id="Anxiety Resources"
                  >
                    Anxiety
                  </a>
                </li>
                <li>
                  <a
                    href="https://firststeps.nz/resources"
                    className="text-blue"
                    data-id="Sleep Resources"
                  >
                    Sleep
                  </a>
                </li>
                <li>
                  <a
                    href="https://firststeps.nz/resources"
                    className="text-blue"
                    data-id="Stress Resources"
                  >
                    Stress
                  </a>
                </li>
                <li>
                  <a
                    href="https://firststeps.nz/resources"
                    className="text-blue"
                    data-id="Fatigue Resources"
                  >
                    Fatigue
                  </a>
                </li>
                <li>
                  <a
                    href="https://firststeps.nz/resources"
                    className="text-blue"
                    data-id="Depression Resources"
                  >
                    Depression
                  </a>
                </li>
                <li>
                  <a
                    href="https://firststeps.nz/resources"
                    className="text-blue"
                    data-id="Mindfulness Resources"
                  >
                    Mindfulness
                  </a>
                </li>
                <li>
                  <a
                    href="https://firststeps.nz/resources"
                    className="text-blue"
                    data-id="Resilience Resources"
                  >
                    Resilience
                  </a>
                </li>
                <li>
                  <a
                    href="https://firststeps.nz/resources"
                    className="text-blue"
                    data-id="Crisis Management Resources"
                  >
                    Crisis Management
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="relative my-6">
          <div className="absolute inset-0 flex items-center">
            <div className="w-full border-t border-gray-300" />
          </div>
          <div className="relative flex justify-center text-sm">
            <span className="px-2 bg-white text-gray-500">Or </span>
          </div>
        </div>
        <div className="bg-white shadow sm:rounded-lg">
          <div className="px-4 py-5 sm:p-6">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Try Clearhead
            </h3>
            <div className="mt-2 max-w-xl text-sm text-gray-500">
              <p>Come and talk to our friendly wellbeing assistant for:</p>
              <ul className="list-disc pl-6">
                <li>A more in-depth Check Up.</li>
                <li>Digital tools to manage your emotions and cope better.</li>
                <li>
                  A deeper understanding of what’s causing your challenges.
                </li>
                <li>Or book a therapy session with a therapist near you!</li>
              </ul>
            </div>
            <div className="mt-3 text-sm">
              <a
                href="https://firststeps.clearhead.org.nz"
                className="font-medium text-blue hover:text-blue"
                data-id="Try Clearhead"
                target="_blank"
              >
                {" "}
                Learn more <span aria-hidden="true">&rarr;</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Results;
