import { useWizardContext } from "../../hooks/useWizard";
import ClearheadCharacter from "../ClearheadCharacter";

const Greeting = () => {
  const context = useWizardContext();
  return (
    <div>
      <div className="mt-8 mb-8 text-center">
        <ClearheadCharacter />
        <p className="mb-3">
          Hi {context.data?.name}, it’s nice to meet you 😊
        </p>
        <p className="mb-3">
          I’m going to run you through a few questions so we can figure out how
          I can help you. It’ll only take 2-5 minutes.{" "}
        </p>
        <p>Just so you know, the conversations we have are confidential.</p>
      </div>
      <div className="flex justify-between">
        <button
          type="button"
          onClick={context.onPrevious}
          className="inline-flex items-center px-4 py-2 border border-blue text-sm font-medium rounded-full shadow-sm text-blue bg-transparent focus:outline-none"
        >
          Previous
        </button>
        <button
          type="button"
          onClick={context.onNext}
          data-id="Greeting Step Complete"
          className={`inline-flex items-center px-5 py-2 border border-transparent text-base font-medium rounded-full shadow-sm text-white cursor-pointer bg-blue hover:bg-blue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue`}
        >
          Let’s go
        </button>
      </div>
    </div>
  );
};

export default Greeting;
