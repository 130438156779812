import * as React from "react";
import { useWizardContext } from "../../hooks/useWizard";
import ClearheadCharacter from "../ClearheadCharacter";

export const affectedBy = [
  "Avoidance",
  "Excessive distraction",
  "Emotional outbursts",
  "Drug use",
  "Alcohol use",
];

function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(" ");
}

const CopingMechanism = () => {
  const context = useWizardContext();

  const isCopingMechanismSelected = React.useMemo(() => {
    if (context.data) {
      const isCoping = Object.keys(context.data.copingMechanism).length > 0;
      const isCopingOther =
        context.data.copingMechanismOther &&
        context.data.copingMechanismOther.length;
      return isCoping || isCopingOther;
    }
    return false;
  }, [context.data]);

  return (
    <div>
      <div className="mt-8 mb-4 text-center mx-auto">
        <ClearheadCharacter />
      </div>
      <p className="mb-4 text-center">
        It’s not nice feeling {context.data.mainFeeling.toLowerCase()}
      </p>
      <p className="mb-4 text-center">
        Sometimes we use coping mechanisms to mask the problem. Do you do any of
        these things to cope when feeling{" "}
        {context.data.mainFeeling.toLowerCase()}?
      </p>
      <div className="mb-4">
        <div className="mt-1 mb-6">
          <div className="space-y-4">
            {affectedBy.map((affected) => (
              <button
                key={affected}
                className={classNames(
                  context.data.copingMechanism[affected]
                    ? "border-blue"
                    : "border-gray-300",
                  "w-full block bg-white border rounded-md px-3 py-2 cursor-pointer sm:flex sm:justify-between focus:outline-none"
                )}
                onClick={() => {
                  let data = { ...context.data.copingMechanism };
                  if (context.data.copingMechanism[affected]) {
                    delete data[affected];
                  } else {
                    data[affected] = true;
                  }
                  context.updateData({
                    copingMechanism: data,
                    copingMechanismOther: "",
                  });
                }}
              >
                <div className="flex items-center">
                  <div className="text-sm">
                    <p className="font-medium text-gray-900">{affected}</p>
                  </div>
                </div>
              </button>
            ))}
          </div>
          <input
            type="text"
            name="challengeAffectedBy"
            id="challengeAffectedBy"
            onChange={(e) =>
              context.updateData({
                copingMechanism: {},
                copingMechanismOther: e.currentTarget.value,
              })
            }
            placeholder="Other.."
            value={context.data?.copingMechanismOther ?? ""}
            className="mt-4 shadow-sm focus:ring-blue focus:border-blue block w-full sm:text-sm border-gray-300 rounded-md"
          />
        </div>
      </div>
      <div className="flex justify-between">
        <button
          type="button"
          onClick={context.onPrevious}
          className="inline-flex items-center px-4 py-2 border border-blue text-sm font-medium rounded-full shadow-sm text-blue bg-transparent focus:outline-none"
        >
          Previous
        </button>
        <button
          type="button"
          disabled={!isCopingMechanismSelected}
          onClick={context.onNext}
          data-id="Coping Mechanism Complete"
          className={`${
            isCopingMechanismSelected
              ? "cursor-pointer bg-blue hover:bg-blue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue"
              : "cursor-not-allowed bg-gray-300 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-300"
          } inline-flex items-center px-5 py-2 border border-transparent text-base font-medium rounded-full shadow-sm text-white`}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default CopingMechanism;
