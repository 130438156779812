import * as React from "react";
import { RadioGroup } from "@headlessui/react";
import { useWizardContext } from "../../hooks/useWizard";
import ClearheadCharacter from "../ClearheadCharacter";

export const affectedBy = [
  "Sleep",
  "Relationships",
  "Work life",
  "Social life",
];

function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(" ");
}

const AreaOfLife = () => {
  const context = useWizardContext();

  const isAreaOfLifeSelected = React.useMemo(() => {
    if (context.data) {
      const isChallenge =
        context.data.challengeAffectedBy &&
        context.data.challengeAffectedBy.length;
      const isOtherChallenge =
        context.data.challengeAffectedByOther &&
        context.data.challengeAffectedByOther.length;
      return isChallenge || isOtherChallenge;
    }
    return false;
  }, [context.data]);

  return (
    <div>
      <div className="mt-8 mb-4 text-center mx-auto">
        <ClearheadCharacter />
      </div>
      <p className="mb-4 text-center">
        Start by bringing to mind the main challenge you’re facing in your
        life...
      </p>
      <p className="mb-4 text-center">
        What area of your life is your challenge affecting the most? Select your
        main one.
      </p>
      <div className="mb-4">
        <div className="mt-1 mb-6">
          <RadioGroup
            value={context.data?.challengeAffectedBy}
            onChange={(val) =>
              context.updateData({
                challengeAffectedBy: val,
                challengeAffectedByOther: "",
              })
            }
          >
            <div className="space-y-4">
              {affectedBy.map((affected) => (
                <RadioGroup.Option
                  key={affected}
                  value={affected}
                  className={({ checked }) =>
                    classNames(
                      checked ? "border-blue" : "border-gray-300",
                      "relative block bg-white border rounded-md px-3 py-2 cursor-pointer sm:flex sm:justify-between focus:outline-none"
                    )
                  }
                >
                  {() => (
                    <div className="flex items-center">
                      <div className="text-sm">
                        <RadioGroup.Label
                          as="p"
                          className="font-medium text-gray-900"
                        >
                          {affected}
                        </RadioGroup.Label>
                      </div>
                    </div>
                  )}
                </RadioGroup.Option>
              ))}
            </div>
          </RadioGroup>
          <input
            type="text"
            name="challengeAffectedBy"
            id="challengeAffectedBy"
            onChange={(e) =>
              context.updateData({
                challengeAffectedBy: "",
                challengeAffectedByOther: e.currentTarget.value,
              })
            }
            placeholder="Other.."
            value={context.data?.challengeAffectedByOther ?? ""}
            className="mt-4 focus:ring-blue focus:border-blue block w-full sm:text-sm border-gray-300 rounded-md"
          />
        </div>
      </div>
      <div className="flex justify-between">
        <button
          type="button"
          onClick={context.onPrevious}
          className="inline-flex items-center px-4 py-2 border border-blue text-sm font-medium rounded-full shadow-sm text-blue bg-transparent focus:outline-none"
        >
          Previous
        </button>
        <button
          type="button"
          disabled={!isAreaOfLifeSelected}
          onClick={context.onNext}
          data-id="Area of Life Complete"
          className={`${
            isAreaOfLifeSelected
              ? "cursor-pointer bg-blue hover:bg-blue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue"
              : "cursor-not-allowed bg-gray-300 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-300"
          } inline-flex items-center px-5 py-2 border border-transparent text-base font-medium rounded-full shadow-sm text-white`}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default AreaOfLife;
