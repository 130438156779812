import * as React from "react";
import { RadioGroup } from "@headlessui/react";
import { useWizardContext } from "../../hooks/useWizard";
import ClearheadCharacter from "../ClearheadCharacter";

const responses: Record<string, string> = {
  Sleep:
    "Good sleep is fundamental to wellbeing 😴. Let's see what's getting in the way.",
  Relationships:
    "Relationships can really play on your mind when they’re not going well 😓. Let’s see what’s getting in the way.",
  "Work life":
    "It’s important to be fulfilled by your work to feel good in everyday life 🌼. Let’s see what’s getting in the way",
  "Social life":
    "It’s so important for our wellbeing to feel connected to other people 🥰. Let’s see what’s getting in the way.",
  Other: "Thanks for sharing, I'm starting to understand you better.",
};

const emotions: string[] = [
  "Anxious",
  "Stressed",
  "Depressed",
  "Frustrated",
  "Sad",
  "Flat",
  "Lonely",
  "Unmotivated",
];

function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(" ");
}

const MainFeeling = () => {
  const context = useWizardContext();

  const isMainFeeling = React.useMemo(() => {
    if (context.data) {
      return context.data.mainFeeling && context.data.mainFeeling.length > 0;
    }
    return false;
  }, [context.data]);

  return (
    <div>
      <div className="mt-8 mb-4 text-center mx-auto">
        <ClearheadCharacter />
      </div>
      <p className="mb-4 text-center">
        {responses[context.data?.challengeAffectedBy ?? "Other"]}
      </p>
      <p className="mb-4 text-center">
        {context.data?.challengeAffectedBy &&
        context.data?.challengeAffectedBy.length > 0
          ? `How are you feeling in relation to what’s happening in your ${context.data?.challengeAffectedBy.toLowerCase()}? Select your
          main feeling.`
          : `How are you feeling in relation to what’s happening with "${context.data?.challengeAffectedByOther?.toLowerCase()}"? Select your
          main feeling.`}
      </p>
      <div className="mb-4">
        <div className="mt-1 mb-6">
          <RadioGroup
            value={context.data?.mainFeeling}
            onChange={(val) =>
              context.updateData({
                mainFeeling: val,
              })
            }
          >
            <div className="space-y-4">
              {emotions.map((emotion) => (
                <RadioGroup.Option
                  key={emotion}
                  value={emotion}
                  className={({ checked }) =>
                    classNames(
                      checked ? "border-blue" : "border-gray-300",
                      "relative block bg-white border rounded-md px-3 py-2 cursor-pointer sm:flex sm:justify-between focus:outline-none"
                    )
                  }
                >
                  {() => (
                    <div className="flex items-center">
                      <div className="text-sm">
                        <RadioGroup.Label
                          as="p"
                          className="font-medium text-gray-900"
                        >
                          {emotion}
                        </RadioGroup.Label>
                      </div>
                    </div>
                  )}
                </RadioGroup.Option>
              ))}
            </div>
          </RadioGroup>
        </div>
      </div>
      <div className="flex justify-between">
        <button
          type="button"
          onClick={context.onPrevious}
          className="inline-flex items-center px-4 py-2 border border-blue text-sm font-medium rounded-full shadow-sm text-blue bg-transparent focus:outline-none"
        >
          Previous
        </button>
        <button
          type="button"
          disabled={!isMainFeeling}
          onClick={context.onNext}
          data-id="Main Feeling Complete"
          className={`${
            isMainFeeling
              ? "cursor-pointer bg-blue hover:bg-blue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue"
              : "cursor-not-allowed bg-gray-300 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-300"
          } inline-flex items-center px-5 py-2 border border-transparent text-base font-medium rounded-full shadow-sm text-white`}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default MainFeeling;
