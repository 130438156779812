const withAnd = (arr: string[]) => {
  if (arr && arr.length > 1) {
    const last = arr.pop();
    const result = arr.join(", ") + " and " + last;
    return result;
  } else {
    if (arr && arr.length === 1) {
      return arr[0];
    }
  }
  return "";
};

export default withAnd;
